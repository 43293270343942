@import "~bootstrap/dist/css/bootstrap.min.css";
@import '~react-toastify/dist/ReactToastify.css';

@import './colors.css';


.br_pluginconnection_mtr {
    margin-top: 1rem;
}

.br_pluginconnection_half_mtr {
    margin-top: 0.5rem;
}

.br_pluginconnection_coi {
    cursor: pointer;
    font-size: 1.5rem;
    color: var(--busy--color);
    float: right;
}

.br_pluginconnection_halfbold {
    font-weight: 500;
}
